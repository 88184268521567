import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import NotificationBadge from 'react-notification-badge';
import { Effect } from 'react-notification-badge';
import GroceryList from '../floatingGroceryList/GroceryList';
import { connect } from 'react-redux';

class RightSidebar extends Component {

    state = {
        popoverOpen: false,
    }

    toggle = () => {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    render() {
        let imgsrc = this.props.themeMapping.clientIcon; 
        return (
            <div
                id="right-sidebar"
                className="right-sidebar">

                <div className="right-sidebar-header">
                    <NavLink to={"/"} className="simple-text logo-mini">
                        <div className="logo-img">
                            <img className="right-sidebar-img-logo" src={imgsrc} alt="react-logo" />
                        </div>
                    </NavLink>
                </div>
                <div className="right-sidebar-wrapper">
                    <ul className="nav">

                        <li>
                            <NavLink
                                to={"list.routepath"}
                                className="nav-link main-list"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.toggle();
                                }}>

                                <i id="desktop-grocery" className={"fas fa-shopping-basket"} >
                                    <NotificationBadge count={this.props.groceryList.length} effect={Effect.SCALE} className="custom-grocery-notification-badge" />
                                </i>

                            </NavLink>
                        </li>
                    </ul>
                </div>

                <GroceryList
                    isOpen={this.state.popoverOpen}
                    toggle={this.toggle}
                    placement={"left"}
                    target="desktop-grocery"
                    isMobile={false} />

            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        groceryList: state.groceryList.list,
    };
}

export default connect(mapStateToProps)(RightSidebar);
