import React, { Fragment } from "react";
import SidebarWrapper from "./sidebar.style";
import Radium from "radium";
import NavList from "components/sidebar/NavList";
import { sidebarData } from "util/data/sidebar";
import { iconDemo, iconAppName } from "helper/constant";
import { NavLink } from "react-router-dom";
import IntlMessages from "util/intlMessages";

import { withRouter } from 'react-router-dom';
import ShareFlyer from '../share/ShareFlyer';
import { isIE } from 'react-device-detect';

const Sidebar = props => {
    let listNameStyle;
    let sidebar;
    let appNameStyle = {};

    const {
        mini,
        drawerWidth,
        miniDrawerWidth,
        onMouseEnter,
        onMouseLeave,
        sidebarTheme,
        closeDrawer,
        themeSetting
    } = props;

    if (themeSetting.sidebarTransParentValue === "on") {
        sidebar = {
            backgroundImage: `linear-gradient(0deg,rgba(0, 0, 0, 0.8),rgba(0, 0, 0, 0.9)),url(${
                themeSetting.transparentImage
                })`,
            backgroundRepeat: "no-repeat, repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            width: mini ? miniDrawerWidth : drawerWidth,
            "@media (max-width: 767.98px)": {
                width: mini ? 0 : drawerWidth
            }
        };
    } else {
        sidebar = {
            width: mini ? miniDrawerWidth : drawerWidth,
            background: sidebarTheme.backgroundColor,
            "@media (max-width: 767.98px)": {
                width: mini ? 0 : drawerWidth
            }
        };
    }

    const closeIcon = {
        "@media (max-width: 767.98px)": {
            display: "block"
        }
    };

    if (mini) {
        listNameStyle = {
            opacity: miniDrawerWidth === drawerWidth ? 1 : 0,
            transform:
                miniDrawerWidth === drawerWidth
                    ? "translateZ(0)"
                    : "translate3d(-25px,0,0)"
        };
        if(isIE){
            appNameStyle = {
                height: miniDrawerWidth === drawerWidth ? "" : "0px"
            }
        }
        
    } else {
        listNameStyle = {
            opacity: !mini ? 1 : 0,
            transform: !mini ? "translateZ(0)" : "translate3d(-25px,0,0)"
        };
        if(isIE){
            appNameStyle = {
                height: !mini ? "" : "0px"
            }
        }
        
    }

    return (
        <SidebarWrapper
            themeSetting={themeSetting}
            sidebarTheme={sidebarTheme}
            mini={mini}
            miniDrawerWidth={miniDrawerWidth}
            drawerWidth={drawerWidth}
        >
            <div
                id="sidebar"
                className="sidebar sideBack"
                style={sidebar}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <div className="sidebar-header">
                    <NavLink to={"/"} className="simple-text logo-mini" onClick={() => props.forceDrawerMini()}>
                        <div className="logo-img">
                            <img src={iconDemo} alt="react-logo" />
                        </div>
                    </NavLink>

                    <div className="logo-text simple-text custom-sidebar-header" style={appNameStyle} ><img src={iconAppName} alt="react-logo" /></div>
                </div>
                <div
                    className="close-drawer-icon"
                    style={closeIcon}
                    onClick={closeDrawer()}
                >
                    <i className="fas fa-times-circle" />
                </div>

                <div className="sidebar-wrapper">
                    <ul className="nav">
                        {sidebarData.map((list, i) => {
                            return (
                                <Fragment key={i}>
                                    {list.type &&
                                        list.type === "heading" ? (
                                            (!mini ||
                                                miniDrawerWidth ===
                                                drawerWidth) && (
                                                <div className="sidelist-header-name">
                                                    {
                                                        <IntlMessages
                                                            id={list.name}
                                                        />
                                                    }
                                                </div>
                                            )
                                        ) : (
                                            <NavList
                                                listNameStyle={listNameStyle}
                                                list={list}
                                                mini={mini}
                                                miniDrawerWidth={
                                                    miniDrawerWidth
                                                }
                                                drawerWidth={drawerWidth}
                                                forceDrawerMini={props.forceDrawerMini}
                                                {...props}
                                            />
                                        )}
                                </Fragment>
                            );
                        })}
                        <ShareFlyer forceDrawerMini={props.forceDrawerMini} pStyle={listNameStyle} />
                    </ul>
                    {/* <div style={{position: "absolute", bottom: "0px"}}>Powered by Luigi D'Arco</div> */}
                </div>
            </div>
        </SidebarWrapper>
    );
};

export default withRouter(Radium(Sidebar));
