import React, { Component } from 'react';
import Carousel from 'nuka-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup } from "reactstrap";
import {
    faAngleLeft,
    faAngleRight
} from '@fortawesome/free-solid-svg-icons';

import CustomSlide from './CustomSlide';
import CustomIndex from './CustomIndex';
import {
    isMobile,
    isIE
} from "react-device-detect";
import '../../assets/css/custom.css';
import axios from 'axios';

class CustomCarousel extends Component {

    state = {
        slideIndex: 0,
        swipe: true,
    }

    goToPage = (index) => {
        //TODO
        //axios per l'incremento del counter di visualizzazioni della pagina index
        /*axios({
            method: 'post',
            url: '',
            headers: {
            },
        })
            .then(res => {

            }).catch(error => {
                //console.log(error);
            });*/

        this.setState({
            slideIndex: index
        }, (() => {
            this.forceUpdate();
        }));
    }

    disableSwipe = () => this.setState({ swipe: !this.state.swipe });
    setSwipe = (swipe) => {
        this.setState({ swipe: swipe });
        if (isIE) {
            // IE + iFrame: la modal si apre dietro l'elemento 'scrollbars'
            // forzo lo z-index
            document.getElementById('scrollbars').style['z-index'] = swipe ? "" : '-1';
        }
    };
    isSwipeDisabed = () => {
        return this.state.swipe ? false : true;
    };

    handleChangeOrientation = () => {
        this.setState({ state: this.state });
    }

    componentDidMount() {

        window.addEventListener('orientationchange', this.handleChangeOrientation);
        let pageIndex = this.props.setPageIndex;
        if (pageIndex !== undefined) {
            pageIndex = parseInt(pageIndex);
            if (isMobile || pageIndex % 2 !== 0) {
                pageIndex--;
            }
            this.goToPage(pageIndex);
        }
        setTimeout(() => window.dispatchEvent(new Event("resize")), 0);
    }

    render() {

        const {
            slideIndex,
            swipe,
        } = this.state;

        //Slides
        const listSlides = this.props.leaflet.pages.map((element, key) =>
            <CustomSlide pageNumber={element.number} key={key} imagePath={element.image_file} products={element.products} leafletSwipe={this.disableSwipe} isSwipeDisabed={this.isSwipeDisabed} setSwipe={this.setSwipe} />
        )

        //Pagination
        const listPages = [];
        listPages.push(<Button className="carousel-pagination-button" key={0} color="primary" onClick={() => this.setState({ slideIndex: 0 })} active={this.state.slideIndex === 0} >1</Button>);
        for (var i = 1; i < this.props.leaflet.pages.length - 2; i = i + 2) {
            let index = i;
            (this.props.leaflet.pages.length === index + 1)
                ? listPages.push(<Button className="carousel-pagination-button" key={index} color="primary" onClick={() => this.setState({ slideIndex: index + 1 })} active={this.state.slideIndex === index + 1} > {i + 1} </Button>)
                : listPages.push(<Button className="carousel-pagination-button" key={index} color="primary" onClick={() => this.setState({ slideIndex: index + 1 })} active={this.state.slideIndex === index + 1} > {i + 1}-{i + 2} </Button>)
        }
        if (this.props.leaflet.pages.length % 2 !== 0) {
            listPages.push(<Button className="carousel-pagination-button" key={this.props.leaflet.pages.length - 1} color="primary" onClick={() => this.setState({ slideIndex: this.props.leaflet.pages.length - 1 })} active={this.state.slideIndex === this.props.leaflet.pages.length - 1} > {this.props.leaflet.pages.length - 1} </Button>);
        }
        // listPages.push(<Button className="carousel-pagination-button" key={this.props.leaflet.pages.length - 1} color="primary" onClick={() => this.setState({ slideIndex: this.props.leaflet.pages.length - 1 })} active={this.state.slideIndex === this.props.leaflet.pages.length - 1} > {this.props.leaflet.pages.length - 1} </Button>);

        var blankDiv;
        if (this.props.leaflet.pages.length % 2 === 0) {
            blankDiv = <div></div>;
        }

        return (
            <div>
                <Carousel
                    heightMode='max'
                    ref="carouselRef"
                    slideIndex={slideIndex}
                    slidesToShow={isMobile ? 1 : 2}
                    slidesToScroll={isMobile ? 1 : 2}
                    cellAlign={isMobile ? "center" : "left"}
                    dragging={swipe}
                    swiping={swipe}
                    speed={500}
                    renderBottomCenterControls={null}
                    renderCenterLeftControls={({ previousSlide }) => {
                        if (isMobile) return null; else return <FontAwesomeIcon className="carousel-arrow-left" icon={faAngleLeft} size="3x" onClick={previousSlide} />;
                    }}
                    renderCenterRightControls={({ nextSlide }) => {
                        if (isMobile) return null; else return <FontAwesomeIcon className="carousel-arrow-right" icon={faAngleRight} size="3x" onClick={nextSlide} />
                    }}
                    afterSlide={slideIndex => this.goToPage(slideIndex)}>

                    {!isMobile && <CustomIndex index={this.props.leaflet.index} goToPage={this.goToPage} />}
                    {listSlides}
                    {!isMobile && blankDiv}

                </Carousel>

                {/*Paginazione*/}
                {
                    !isMobile &&
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                        <ButtonGroup>
                            {listPages}
                        </ButtonGroup>
                    </div>
                }
            </div>
        );
    }
}

export default CustomCarousel;