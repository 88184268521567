import Leaflet from "views/Leaflet";
import Categories from "views/Categories";
import Pages from "views/Pages";

const dashboardRoutes = [
  { path: "/leaflet/:index?", component: Leaflet},
  { path: "/categories/:name?", component: Categories},
  { path: "/pages", component: Pages}
];
 
export default dashboardRoutes;