import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomCarousel from '../components/leaflet/CustomCarousel';
import { serverResponse } from '../util/fakeServer';

class Leaflet extends Component {

  state = {
    leaflet: {},
    setPageIndex: null,
  }

  componentWillMount() {
    this.setState({ setPageIndex: this.props.match.params.index })
  }

  render() {
    
    let tmp = Object.assign({}, serverResponse);

    const {
      setPageIndex
    } = this.state;

    return (
      <div>

        <CustomCarousel leaflet={tmp.leaflet} setPageIndex={setPageIndex} />

      </div>
    );

  }
}


const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    LanguageSwitcher: state.LanguageSwitcher,
    locale: state.LanguageSwitcher.language.locale,
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin
    },
    themeSetting: {
      toolbarAlignValue: state.themeSetting.toolbarAlignValue,
      footerAlignValue: state.themeSetting.footerAlignValue,
      sidebarDisplayValue: state.themeSetting.sidebarDisplayValue,
      toolbarDisplayValue: state.themeSetting.toolbarDisplayValue,
      footerDisplayValue: state.themeSetting.footerDisplayValue,
      sidebarTransParentValue: state.themeSetting.sidebarTransParentValue,
      transparentImage: state.themeSetting.transparentImage,
      activeLinkStyle: state.themeSetting.activeLinkStyle,
      sidebarMiniValue: state.themeSetting.sidebarMiniValue,
      sidebarTransParentActiveBack:
        state.themeSetting.sidebarTransParentActiveBack,
      sidebarTransParentActiveColor:
        state.themeSetting.sidebarTransParentActiveColor
    }
  };
};

export default connect(mapStateToProps, null)(Leaflet);