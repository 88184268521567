import React, { Component } from 'react';
import Icon from '../../../assets/images/icons/play.svg';
import { Player } from 'video-react';
import "../../../assets/css/video-react.css";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col
} from 'reactstrap';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import HatChefIcon from '../../../assets/images/icons/hatchef.svg';
import PlusIcon from '../../../assets/images/icons/plus.svg';
import WorldIcon from '../../../assets/images/icons/world.svg';
import InfoIcon from '../../../assets/images/icons/info.svg';

import '../../../assets/css/custom.css';
import Facebook from '../../../assets/images/icons/facebook.svg';
import Whatsapp from '../../../assets/images/icons/whatsapp.svg';
import { serverResponse } from '../../../util/fakeServer';

export default class PlayMarker extends Component {

    setModal = (swipe) => {
        //TODO
        //axios per l'incremento del counter di visualizzazioni della modal play
        /*
        if(this.state.modal === false){
            axios({
            method: 'post',
            url: '',
            headers: {
            },
        })
            .then(res => {
 
            }).catch(error => {
                //console.log(error);
            });
        }*/
        this.props.setSwipe(swipe);
        //this.props.leafletSwipe();
        this.setState({ modal: !this.state.modal });
    }

    shareOnFacebook = () => {
        let imageSrc = serverResponse.config.serverPath + this.props.info.images[this.props.info.images.length - 1].image_file;
        window.open("https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(imageSrc), '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    }

    shareOnWhatsapp = () => {
        let imageSrc = serverResponse.config.serverPath + this.props.info.images[this.props.info.images.length - 1].image_file;
        //window.location = "http://api.whatsapp.com/send?text=" + serverResponse.config.shareProductSentence + '    ' + encodeURIComponent(imageSrc);
        window.open("http://api.whatsapp.com/send?text=" + serverResponse.config.shareProductSentence + '    ' + encodeURIComponent(imageSrc), '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    }

    state = {
        modal: false,
    }

    render() {

        const markers = this.props.info.markers.map((element, key) => {
            switch (element.type) {
                case "plus": {
                    return <img key={key} src={PlusIcon} className="icon-marker" alt="Info" onClick={() => { this.props.navMarkers("plus"); this.setModal(false) }} />
                }
                case "info": {
                    return <img key={key} src={InfoIcon} className="icon-marker" alt="World" onClick={() => { this.props.navMarkers("info"); this.setModal(false) }} />
                }
                case "world": {
                    return <img key={key} src={WorldIcon} className="icon-marker" alt="World" onClick={() => { this.props.navMarkers("world"); this.setModal(false) }} />
                }
                case "hat-chef": {
                    return <img key={key} src={HatChefIcon} className="icon-marker" alt="World" onClick={() => { this.props.navMarkers("hatchef"); this.setModal(false) }} />
                }
                case "play": {
                    return null
                }
                default: return null
            }
        });

        return (
            <div className="marker vertical" type="play">
                <img id={"play-" + this.props.info.item_id} src={Icon} className="icon-marker" alt="Play" onClick={() => this.setModal(false)} />
                <Modal
                    isOpen={this.state.modal}
                    toggle={() => this.setModal(true)}
                    size="lg"
                >
                    <ModalHeader toggle={() => this.setModal(true)}>Video</ModalHeader>
                    <ModalBody>
                        <Row style={{ marginBottom: "16px" }}>
                            <Col style={{ /*display: "flex", */height: "30px" }}>
                                <div className="markers-container" style={{ width: "100%" }}>
                                    {markers}
                                    <img src={Facebook} className="share-icon" style={{ marginLeft: "auto" }} alt="facebook" onClick={() => this.shareOnFacebook()} />
                                    <img src={Whatsapp} className="share-icon" alt="whatsapp" onClick={() => this.shareOnWhatsapp()} />
                                </div>
                            </Col>
                        </Row>

                        <Player
                            autoPlay={true}
                            playsInline
                            src={this.props.markerInfo.data}
                        />

                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.setModal(true)}>
                            Chiudi
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
} 