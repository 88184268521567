import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import IntlMessages from "util/intlMessages";

class NavList extends Component {
  state = {
    collapse: false
  };

  toggle = e => {
    e.preventDefault();
    this.setState({ collapse: !this.state.collapse });
  };

  componentDidMount = () => {
    // this is for opening collapse for active link
    if (this.props.list.child) {
      const finalList = this.props.list.child.filter(a => !a.hasOwnProperty("child"));
      const threeList = this.props.list.child.filter(a => a.hasOwnProperty("child"));
      const finalThreelevel = [];
      if (threeList.length) {
        threeList.forEach(element => {
          element.child.forEach(ele => {
            finalThreelevel.push(ele.routepath);
          });
        });
      }
      if ((finalList.length &&
        finalList.map(a => a.routepath).includes(this.props.location.pathname)) ||
        (finalThreelevel && finalThreelevel.includes(this.props.location.pathname))) {
        this.setState({
          collapse: true
        })
      }
    }
  }

  render() {
    let dynamicList;
    const {
      listNameStyle,
      list,
      forceDrawerMini
    } = this.props;

    dynamicList = (
      <li>
        <NavLink
          to={list.routepath}
          className="nav-link main-list"
          onClick={
            (e) => {
              forceDrawerMini();
              list.action(e);
            }
          }>

          <i className={list.iconClass} />
          <p style={listNameStyle}>{<IntlMessages id={list.name} />}</p>

        </NavLink>
      </li>
    );

    return <Fragment>{dynamicList}</Fragment>;

  }
}

export default NavList;
