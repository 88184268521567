import React, { Component } from 'react';
import Icon from '../../../assets/images/icons/world.svg';
import '../../../assets/css/custom.css';

export default class WorldMarker extends Component {

    handleClick = () => {
        window.open(this.props.markerInfo.data, '_blank');
    }

    render() {
        return (
            <div className="marker vertical" type="world">
                <img id={"world-" + this.props.info.item_id} src={Icon} className="icon-marker" alt={this.props.markerInfo.title} onClick={this.handleClick} />
            </div>
        );
    }
} 