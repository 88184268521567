import React, { Component } from 'react';
import '../../assets/css/custom.css';
import InfoMarker from './markers/InfoMarker';
import PlusMarker from './markers/PlusMarker';
import WorldMarker from './markers/WorldMarker';
import PlayMarker from './markers/PlayMarker';
import HatChefMarker from './markers/HatChefMarker';
import { isMobile, isTablet } from 'react-device-detect';

export default class CustomProduct extends Component {

    state = {
        childOpen: false
    }

    navMarkers = (newMarker) => {
        //TODO
        //axios per l'incremento del counter di visualizzazioni della modal di tipo newmarker
        /*axios({
            method: 'post',
            url: '',
            headers: {
            },
        })
            .then(res => {
 
            }).catch(error => {
                //console.log(error);
            });*/
        var element = document.getElementById(newMarker + "-" + this.props.info.item_id);
        element.click();
    }

    render() {

        const styleProduct = {
            position: "absolute",
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            top: this.props.info.blueprint.top + "%",
            left: this.props.info.blueprint.left + "%",
            width: this.props.info.blueprint.width + "%",
            height: this.props.info.blueprint.height + "%",
        }

        const markers = this.props.info.markers.map((element, key) => {
            switch (element.type) {
                case "plus": {
                    return <PlusMarker key={key} info={this.props.info} markerInfo={element} leafletSwipe={this.props.leafletSwipe} navMarkers={this.navMarkers} setSwipe={this.props.setSwipe} isSwipeDisabed={this.props.isSwipeDisabed} />
                }
                case "info": {
                    return <InfoMarker key={key} info={this.props.info} markerInfo={element} leafletSwipe={this.props.leafletSwipe} navMarkers={this.navMarkers} setSwipe={this.props.setSwipe} isSwipeDisabed={this.props.isSwipeDisabed} />
                }
                case "world": {
                    return <WorldMarker key={key} info={this.props.info} markerInfo={element} leafletSwipe={this.props.leafletSwipe} navMarkers={this.navMarkers} setSwipe={this.props.setSwipe} isSwipeDisabed={this.props.isSwipeDisabed} />
                }
                case "hat-chef": {
                    return <HatChefMarker key={key} info={this.props.info} markerInfo={element} leafletSwipe={this.props.leafletSwipe} navMarkers={this.navMarkers} setSwipe={this.props.setSwipe} isSwipeDisabed={this.props.isSwipeDisabed} />
                }
                case "play": {
                    return <PlayMarker key={key} info={this.props.info} markerInfo={element} leafletSwipe={this.props.leafletSwipe} navMarkers={this.navMarkers} setSwipe={this.props.setSwipe} isSwipeDisabed={this.props.isSwipeDisabed} />
                }
                default: return null
            }
        });



        return (
            <div className="customProduct" style={styleProduct} onClick={(e) => {
                //controllo che la pagina sia visualizzata da mobile e che sia stato cliccato il quadrato
                if (isMobile && !isTablet && e.target.classList.contains("customProduct")) {
                    var plus = document.getElementById("plus-" + this.props.info.item_id);
                    plus.click();
                }
            }}>
                {markers}
            </div>
        );
    }
}