import React, { Component } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import PageTitle from 'components/common/PageTitle';
import axios from 'axios';
import MyGroceryElement from 'components/myGroceryList/MyGroceryElement';

class PdfDownload extends Component {

    state = {
        list: []
    }

    printDocument = () => {
        /*
        const doc = jsPDF('', 'pt', 'A4');
        doc.addHTML(document.getElementById('divToPrint'), 10, 10, {
            pagesplit: true, margin: { top: 10, right: 10, bottom: 10, left: 10, useFor: 'page' }
        }, function () {
            doc.save("test.pdf")
        })
        */

        const input = document.getElementById('divToPrint');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');

                console.log(canvas.height);

                var imgWidth = 210;
                var pageHeight = 295;
                var imgHeight = canvas.height * imgWidth / canvas.width;
                var heightLeft = imgHeight;
                var doc = new jsPDF('p', 'mm');
                var position = 0;

                console.log(imgHeight);

                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    console.log(heightLeft);
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                    console.log("dOPO");
                    console.log(heightLeft);
                }

                //doc.save("download.pdf");
                //window.close();
            });
    }

    componentDidMount() {
        document.getElementsByTagName("body")[0].style = 'overflow: auto';
        var id = this.props.match.params.id;

        //axios per il caricamento della lista
        axios({
            method: 'get',
            url: 'https:/www.wipdev.it/listaspesaapi/leaflet/v1/api/read?id=' + id,
            headers: {
            },
        })
            .then(res => {
                let tmpProducts = res.data;
                tmpProducts.sort((a, b) => {
                    const [aDone, bDone] = [a.done, b.done];
                    if (aDone === bDone) {
                        const [aID, bID] = [parseInt(a.item_id), parseInt(b.item_id)]
                        if (aID === -1) return +1
                        if (bID === -1) return -1
                        return aID - bID
                    };
                    if (aDone === true) return +1;
                    if (bDone === true) return -1;
                    return 1;
                });

                this.setState({
                    list: tmpProducts
                },
                    this.printDocument);
            }).catch(error => {
                console.log(error);
            });
    }

    render() {
        return (
            <div>
                <div style={coverStyle}>
                    <h1>Stiamo generando la tua lista della spesa...</h1>
                </div>
                <div id="divToPrint" className="mt4" style={{
                    backgroundColor: '#f5f5f5',
                    width: '210mm',
                    minHeight: '297mm',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    paddingTop: '10px'
                }}>

                    <PageTitle title="myGroceryList" className="ml-15" />

                    {this.state.list.map((element, i) => <MyGroceryElement info={element} key={i} checkProduct={this.checkProduct} />)}

                </div>
            </div>)
    }
}

export default PdfDownload;

const coverStyle = {
    position: "fixed",
    left: "0",
    right: "0",
    top: "0",
    bottom: "0",
    backgroundColor: "#FFFFFF",
    zIndex: "1000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}