import { serverResponse } from '../fakeServer';

export const sidebarData = [
    {
      name: 'sidebar.leaflet',
      routepath: '/leaflet',
      iconClass: 'fas fa-book-open',
      action: (e) => {}
    },
    {
      name: 'sidebar.pages',
      routepath: '/pages',
      iconClass: 'fas fa-columns',
      action: (e) => {}
    },
    {
      name: 'sidebar.categories',
      routepath: '/categories',
      iconClass: 'fas fa-list-ul',
      action: (e) => {}
    },
    {
      name: 'sidebar.fullscreen',
      routepath: '/fullScreen',
      iconClass: 'fas fa-arrows-alt',
      action: (e) => {
        e.preventDefault();
        //var routeHeight =document.getElementById("root").firstChild;
        var routeHeight = window.document.documentElement;
        if (routeHeight.requestFullscreen) {
          routeHeight.requestFullscreen();
        } else if (routeHeight.mozRequestFullScreen) { /* Firefox */
          routeHeight.mozRequestFullScreen();
        } else if (routeHeight.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          routeHeight.webkitRequestFullscreen();
        } else if (routeHeight.msRequestFullscreen) { /* IE/Edge */
          routeHeight.msRequestFullscreen();
        }
      }
    },
    {
      name: 'sidebar.pdf',
      routepath: '/Pdf',
      iconClass: 'fas fa-file-pdf',
      action: (e) => {
        e.preventDefault();
        window.open(serverResponse.config.pdfPath, '_blank');
      }
    },
    /*{
      name: 'sidebar.stores',
      routepath: '/stores',
      iconClass: 'fas fa-store-alt',
      action: (e) => {
        e.preventDefault();
        alert("Punti vendita");
      }
    },
    {
      name: 'sidebar.website',
      routepath: '/website',
      iconClass: 'fas fa-globe',
      action: (e) => {
        e.preventDefault();
        window.open('https://www.supermercatidok.it/', '_blank');
      }
    }*/
  ];
  