import React, { Component } from 'react';
import {
    Row,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faShareSquare,
    faFilePdf
} from '@fortawesome/free-solid-svg-icons';
import {
    FacebookShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    TelegramIcon,
    WhatsappIcon,
    EmailIcon,
} from 'react-share';
import { connect } from 'react-redux';
import axios from 'axios';
import {serverResponse} from '../../util/fakeServer';

class ShareGroceryList extends Component {

    state = {
        modal: false,
        idParam: 0,
    }

    openModal = () => {
        this.setState({ modal: true });
    }

    closeModal = () => {
        this.setState({ modal: false });
    }

    exportGroceryList = () => {

        var tmpProducts = this.props.groceryList.list;

        tmpProducts.forEach((element) => {
            element.image_file = element.images[0] ? element.images[0].image_file : "";
        })

        var exportObj = {
            "id_param_url": this.state.idParam,
            "id_campagna": this.props.groceryList.idCampaign,
            "prodotti": this.props.groceryList.list
        }

        axios.post('https://www.wipdev.it/listaspesaapi/leaflet/v1/api/write', exportObj)

        var idParam = Math.random().toString(36).substr(2, 16);
        this.setState({
            idParam: idParam
        });
    }

    componentDidMount() {
        var idParam = Math.random().toString(36).substr(2, 16);

        this.setState({
            idParam: idParam
        });
    }

    render() {

        let link = serverResponse.config.serverPath + "/myGroceryList/" + this.state.idParam;
        let title = "Visualizza la tua lista della spesa";

        return (
            <div>
                <FontAwesomeIcon icon={faShareSquare} onClick={() => this.openModal()} />
                <Modal
                    isOpen={this.state.modal}
                    toggle={() => this.closeModal()}
                    centered={true}
                >
                    <ModalHeader toggle={() => this.closeModal()}>Condividi con...</ModalHeader>
                    <ModalBody>
                        <Row className="custom-share-container">

                            <FacebookShareButton
                                url={link}
                                beforeOnClick={() => {
                                    this.exportGroceryList()
                                }}>
                                <FacebookIcon size={32} round={true} />
                            </FacebookShareButton>

                            <TwitterShareButton
                                title={title}
                                url={link}
                                beforeOnClick={() => {
                                    this.exportGroceryList()
                                }}>
                                <TwitterIcon size={32} round={true} />
                            </TwitterShareButton>

                            <TelegramShareButton
                                title={title}
                                url={link}
                                beforeOnClick={() => {
                                    this.exportGroceryList()
                                }}>
                                <TelegramIcon size={32} round={true} />
                            </TelegramShareButton>

                            <WhatsappShareButton
                                title={title}
                                url={link}
                                beforeOnClick={() => {
                                    this.exportGroceryList()
                                }}>
                                <WhatsappIcon size={32} round={true} />
                            </WhatsappShareButton>

                            <EmailShareButton
                                url={link}
                                beforeOnClick={() => {
                                    this.exportGroceryList()
                                }}>
                                <EmailIcon size={32} round={true} />
                            </EmailShareButton>
                            {/*
                            <div 
                                style={circleIcon} 
                                onClick={() => {
                                    this.exportGroceryList();
                                    window.open('/pdfDownload/' + this.state.idParam, '_blank')
                                }}>
                                <i class="fa fa-file-pdf" style={pdfIcon} />
                            </div>
                            */}

                        </Row>
                    </ModalBody>
                </Modal>

            </div>
        );
    }
}

const circleIcon = {
    backgroundColor: "#ff0000",
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}

const pdfIcon = {
    fontSize: "17px",
    margin: "0px",
    color: "white"
}

const mapStateToProps = state => {

    return {
        groceryList: state.groceryList,
    };

}

export default connect(mapStateToProps, null)(ShareGroceryList);