import React, { Component } from 'react';

import '../../assets/css/product.css';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import {
    Row,
    Col,
    Label,
    InputGroup,
    InputGroupAddon,
    Input,
    Button,
    Tooltip
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus,
    faMinus,
    faPlusCircle,
    faMinusCircle,
    faBars,
    faShoppingBasket
} from '@fortawesome/free-solid-svg-icons';

import AddProd from '../../assets/images/icons/white_add_prod.png';
import Facebook from '../../assets/images/icons/facebook.svg';
import Whatsapp from '../../assets/images/icons/whatsapp.svg';

import PlusMarker from '../../components/leaflet/markers/PlusMarker';
import HatChefMarker from '../../components/leaflet/markers/HatChefMarker';
import InfoMarker from '../../components/leaflet/markers/InfoMarker';
import PlayMarker from '../../components/leaflet/markers/PlayMarker';
import WorldMarker from '../../components/leaflet/markers/WorldMarker';
import { connect } from 'react-redux';
import actions from '../../redux/groceryList/action';

import { Textfit } from 'react-textfit';

import { NotificationManager } from 'react-notifications';
import { serverResponse } from '../../util/fakeServer';

import {strings} from '../../util/data/strings';

class Product extends Component {

    state = {
        itemCarousel: 0,
        varietySelected: (this.props.info.varieties ? this.props.info.varieties[0] : ""),
        saveTooltipOpen: false,
    }

    componentWillReceiveProps(props) {
        this.setState({
            quantity: (props.info.quantity_step ? props.info.quantity_step : 1),
            varietySelected: (props.info.varieties ? props.info.varieties[0] : "")
        })
    }

    componentDidMount() {
        this.setState({ quantity: (this.props.info.quantity_step ? this.props.info.quantity_step : 1) })
    }

    navMarkers = (newMarker) => {
        var element = document.getElementById(newMarker + "-" + this.props.info.item_id);
        element.click();
    }

    incrementQty = () => {
        this.setState({ quantity: this.state.quantity + (this.props.info.quantity_step ? this.props.info.quantity_step : 1) });
    }

    decrementQty = () => {
        if (this.props.info.quantity_step) {
            if (this.state.quantity > this.props.info.quantity_step) {
                this.setState({ quantity: this.state.quantity - (this.props.info.quantity_step) });
            }
        } else if (this.state.quantity > 1) {
            this.setState({ quantity: this.state.quantity - 1 });
        }
    }

    shareOnFacebook = () => {
        /* RETRIEVE PRODUCT PAGE TO BUILD LINK
        let indexPage = -1;
        serverResponse.leaflet.pages.forEach(element => {
            var index = element.products.indexOf(this.props.info);
            if(index !== -1){
                indexPage = element.number;
                return;
            }
        });
        console.log("indexPage: " + indexPage);return false;
        */
        let imageSrc = serverResponse.config.serverPath + this.props.info.images[this.props.info.images.length - 1].image_file;
        window.open("https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(imageSrc), '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    }

    shareOnWhatsapp = () => {
        let imageSrc = serverResponse.config.serverPath + this.props.info.images[this.props.info.images.length - 1].image_file;
        //window.location = "http://api.whatsapp.com/send?text=" + serverResponse.config.shareProductSentence + '    ' + encodeURIComponent(imageSrc);
        window.open("http://api.whatsapp.com/send?text=" + serverResponse.config.shareProductSentence + '    ' + encodeURIComponent(imageSrc), '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    }

    handleAddProduct = () => {
        let obj = Object.assign({}, this.props.info);
        obj.quantity = this.state.quantity;
        obj.done = false;
        obj.variety = this.state.varietySelected;
        this.props.addProduct(obj);
        NotificationManager.success('Controlla la tua lista della spesa', 'Prodotto aggiunto', 800);
    }

    handleChangeVariety = (e) => {
        this.setState({
            varietySelected: e.target.value
        })
    }

    toggleSaveTooltip = () => {
        this.setState({ saveTooltipOpen: !this.state.saveTooltipOpen });
    }

    render() {

        const {
            info
        } = this.props;

        const markers = info.markers.map((element, key) => {

            switch (element.type) {
                case "plus": {
                    return <PlusMarker key={key} info={info} markerInfo={element} leafletSwipe={() => { }} navMarkers={this.navMarkers} setSwipe={() => { }} />
                }
                case "info": {
                    return <InfoMarker key={key} info={info} markerInfo={element} leafletSwipe={() => { }} navMarkers={this.navMarkers} setSwipe={() => { }} />
                }
                case "world": {
                    return <WorldMarker key={key} info={info} markerInfo={element} leafletSwipe={() => { }} navMarkers={this.navMarkers} setSwipe={() => { }} />
                }
                case "hat-chef": {
                    return <HatChefMarker key={key} info={info} markerInfo={element} leafletSwipe={() => { }} navMarkers={this.navMarkers} setSwipe={() => { }} />
                }
                case "play": {
                    return <PlayMarker key={key} info={info} markerInfo={element} leafletSwipe={() => { }} navMarkers={this.navMarkers} setSwipe={() => { }} />
                }
                default: return null
            }
        });

        let varieties = <Row className="varieties-container"></Row>;
        if (this.props.info.varieties) {
            if (this.props.info.varieties.length > 0) {
                varieties = <Row style={{ minHeight: "100px" }}>
                    <Col md="12">
                        <Label for="variety">Scegli:</Label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend"><span className="input-group-text icon-input-group"><FontAwesomeIcon icon={faBars} /></span></InputGroupAddon>
                            <Input type="select" name="select" id="variety" value={this.state.varietySelected} onChange={this.handleChangeVariety}>
                                {
                                    this.props.info.varieties.map((element, key) => {
                                        return <option key={key} value={element}>{element}</option>
                                    })
                                }
                            </Input>
                        </InputGroup>
                    </Col>
                </Row>;
            }
        }

        let brandImg = "";
        if(serverResponse.config.brandImage !== "" && this.props.info.pam === true) {
            brandImg = <img style={{width:"30%"}} src={serverResponse.config.brandImage} alt="img" />;
        }

        let cardImg = "";
        if(serverResponse.config.underPriceImage !== "" && this.props.info.underpriced_product === true) {
            cardImg = <img style={{ width: "30%"}} src={serverResponse.config.underPriceImage} alt="img" />;
        }

        return (
            <div className="col-sm-12 ptb-15">
                <div className="roe-card-style">
                    <div className="roe-card-header custom-card-header">
                        <Row>
                            <Col md="12">
                                <div className="markers-container">
                                    {markers}
                                    <img src={Facebook} className="share-icon" style={{ marginLeft: "auto" }} alt="facebook" onClick={() => this.shareOnFacebook()} />
                                    <img src={Whatsapp} className="share-icon" alt="whatsapp" onClick={() => this.shareOnWhatsapp()} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="roe-card-body">
                        <Row>
                            <Col md="6">
                                <Carousel
                                    swipeable={true}
                                    emulateTouch={true}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    showThumbs={false}
                                    showIndicators={false}
                                    selectedItem={this.state.itemCarousel}
                                    onChange={(current) => this.setState({ itemCarousel: current })}
                                >
                                    {
                                        info.images.map((image, key) => <img key={key} src={image.image_file} alt="img" />
                                        )
                                    }

                                </Carousel>
                            </Col>
                            <Col md="6" className="container-fields">
                                <Textfit mode="multi">
                                    <span style={{ textTransform: "uppercase", fontWeight: "bold" }}>{info.field1}</span>
                                </Textfit>
                                
                                <Textfit mode="multi" max={15}>
                                    <span style={{ textTransform: "uppercase" }}>{info.field2}</span>
                                </Textfit>
                                <h6 style={{ textTransform: "lowercase" }}>{info.field3}</h6>
                                <h6 style={{ textTransform: "lowercase" }}>{info.field4}</h6>
                                {brandImg}
                                {cardImg}
                            </Col>
                        </Row>
                        <Row style={{ justifyContent: "space-between" }}>
                            <Col xl="12" sm="12" lg="12" md="12" xs="12">
                                <div className="product-price custom-product-price-varieties">
                                    {<p>{info.price_label}</p>}
                                    {/*<Textfit mode="single">
                                        <p style={{ fontWeight: "bold", fontSize: "3rem" }}>{info.price_label}</p>
                                </Textfit>*/}
                                </div>
                            </Col>
                            <Col xl="12" sm="12" lg="12" md="12" xs="12">
                                <div>
                                    {varieties}
                                </div>
                            </Col>
                        </Row>
                        {/*<Row>
                            <Col md="12">
                                {varieties}
                            </Col>
                        </Row>*/}
                        <Row>
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <Col md="6" className="product-price">
                                    <div className="container-add-to-list">
                                        <FontAwesomeIcon className="change-product-quantity-icon" icon={faMinusCircle} onClick={() => this.decrementQty()} />
                                        <h6>{this.state.quantity}</h6>
                                        <h6 style={{ fontWeight: "bold" }}>{info.grocery_label}</h6>
                                        <FontAwesomeIcon className="change-product-quantity-icon" icon={faPlusCircle} onClick={() => this.incrementQty()} />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="container-add-to-list" style={{ justifyContent: "flex-end" }}>
                                        <Button id={"btnSave_" + info.item_id} color="primary" onClick={this.handleAddProduct}>
                                            {/*<FontAwesomeIcon icon={faShoppingBasket} />*/}
                                            <img src={AddProd} style={{ color: "#00ff00", width: "30px", cursor: "pointer" }} alt="img" />
                                        </Button>

                                        <Tooltip placement="top" isOpen={this.state.saveTooltipOpen} target={"btnSave_" + info.item_id} toggle={this.toggleSaveTooltip}>
                                            {strings.saveBtnTooltip}
                                        </Tooltip>
                                    </div>
                                </Col>
                            </div>
                        </Row>
                    </div>
                </div>

            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addProduct: (newProduct) => {
            dispatch(actions.addProduct(newProduct));
        },
    }
}

export default connect(null, mapDispatchToProps)(Product);